"use client";

import { Close, Close24xLarge, Favourite } from "@/components/icons";
import { AmountDot } from "@/components/ui/AmountDot";
import { Button } from "@/components/ui/button";
import {
	ModalBody,
	ModalClose,
	ModalContent,
	ModalHeader,
	ModalRoot,
	ModalViewport,
} from "@/components/ui/modal";
import { Text } from "@/components/ui/Text";
import { useModal } from "@/hooks/useModal";
import { DialogClose } from "@frend-digital/ui";
import { Suspense } from "react";

import { IconButton } from "@/components/ui/iconButton";
import { type ProductCard } from "@/lib/centra/formatters";
import { useWishlist } from "move-to-front/hooks/useWishlist";
import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { fetchProductCardsByIds_auth } from "../products/actions";
import styles from "./index.module.css";

export const WishlistItem = ({ item }: { item: ProductCard }) => {
	const { remove } = useWishlist({ fetcher: fetchProductCardsByIds_auth });
	const media = item.media.at(0);
	const isOnSale = item.priceBeforeDiscountAsNumber > item.priceAsNumber;
	return (
		<article className={styles.item}>
			<Link className={styles.link} href={`/product/${item.uri}`}>
				<div className={styles.content}>
					{media && (
						<Image
							className={styles.image}
							src={media?.href}
							width={82}
							height={102}
							alt="product"
						/>
					)}
					<div className={styles.info}>
						<span className={styles.title}>{item.name}</span>
						<span className={styles.variant}>{item.variantName}</span>
						<span className={styles.price}>
							{item.price}
							{isOnSale && (
								<span className={styles.priceBeforeDiscount}>
									{item.priceBeforeDiscount}
								</span>
							)}
						</span>
					</div>
				</div>
			</Link>
			<IconButton
				className={styles.remove}
				onClick={() => remove(item.id)}
				color="white"
				size="medium">
				<Close />
			</IconButton>
		</article>
	);
};

export const WishlistModal = () => {
	const t = useTranslations("wishlist.modal");
	const { isOpen, setOpen } = useModal("wishlist");
	const { products, clear } = useWishlist({ fetcher: fetchProductCardsByIds_auth });

	return (
		<ModalRoot open={isOpen} onOpenChange={setOpen}>
			<ModalViewport>
				<ModalContent className={styles.root}>
					<ModalHeader>
						<div>
							<Text className={styles.info} asChild variant="h4" bold>
								<h2>
									{t("header")}{" "}
									<AmountDot variant="beige" size="xSmall">
										{products?.length}
									</AmountDot>
								</h2>
							</Text>

							{products?.length ? (
								<Text
									className={styles.clear}
									onClick={() => clear()}
									variant="body2"
									color="primary-dark-blue-06">
									{t("clear")}
								</Text>
							) : null}
						</div>

						<ModalClose asChild>
							<DialogClose asChild>
								<Button variant="unstyled">
									<Close24xLarge />
								</Button>
							</DialogClose>
						</ModalClose>
					</ModalHeader>
					<ModalBody>
						<div className={styles.items}>
							{products?.map((item, i) => <WishlistItem item={item} key={i} />)}
						</div>
					</ModalBody>
				</ModalContent>
			</ModalViewport>
		</ModalRoot>
	);
};

export const UnsuspendedWishlistModalTrigger = ({
	className,
}: {
	className?: string;
}) => {
	const { setOpen } = useModal("wishlist");
	const { products } = useWishlist({ fetcher: fetchProductCardsByIds_auth });

	return (
		<div className={className} onClick={() => setOpen(true)}>
			<AmountDot size="notification" bold variant="yellow" amount={products?.length ?? 0}>
				<Favourite />
			</AmountDot>
		</div>
	);
};

export const WishlistModalTrigger: typeof UnsuspendedWishlistModalTrigger = (props) => {
	return (
		<Suspense
			fallback={
				<div className={props.className}>
					<Favourite />
				</div>
			}>
			<UnsuspendedWishlistModalTrigger {...props} />
		</Suspense>
	);
};
