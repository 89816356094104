import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
	server: {
		PREVIEW_TOKEN: z.string().min(1),
		STORYBLOK_SPACE_ID: z.coerce.number().positive(),
		REVALIDATE_SECRET: z.string().min(1),
		STORYBLOK_TOKEN: z.string().min(1),
		CENTRA_CHECKOUT_SECRET: z.string().min(1),
		CENTRA_INTEGRATION_URL: z.string().url(),
		CENTRA_INTEGRATION_TOKEN: z.string().min(1),
		LOGISTRA_API_KEY: z.string().min(1),
		LOGISTRA_SENDER_ID: z.string().min(1),
		CENTRA_DOMAIN: z.string().min(1),
		ADYEN_API_PREFIX: z.string().min(1),
		ADYEN_API_SECRET: z.string().min(1),
		STASH_REVALIDATE_SECRET: z.string().min(1),
		DEDUPE_SECRET: z.string().min(1),
	},
	client: {
		NEXT_PUBLIC_CENTRA_CHECKOUT: z.string().url(),
		NEXT_PUBLIC_GTM_ID: z.string().min(1),
		NEXT_PUBLIC_PROJECT: z.string().min(1),
		NEXT_PUBLIC_BASE_URL: z.string().min(1),
		NEXT_PUBLIC_MAPBOX_STYLE_URL: z.string().min(1),
		NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN: z.string().min(1),
		NEXT_PUBLIC_CENTRA_DOMAIN: z.string().min(1),
		NEXT_PUBLIC_DEDUPE_URL: z.string().min(1),
		NEXT_PUBLIC_STASH_URL: z.string().min(1),
	},
	runtimeEnv: {
		ADYEN_API_PREFIX: process.env.ADYEN_API_PREFIX,
		ADYEN_API_SECRET: process.env.ADYEN_API_SECRET,
		CENTRA_DOMAIN: process.env.CENTRA_DOMAIN,
		CENTRA_CHECKOUT_SECRET: process.env.CENTRA_CHECKOUT_SECRET,
		CENTRA_INTEGRATION_URL: process.env.CENTRA_INTEGRATION_URL,
		NEXT_PUBLIC_CENTRA_CHECKOUT: process.env.NEXT_PUBLIC_CENTRA_CHECKOUT,
		STORYBLOK_TOKEN: process.env.STORYBLOK_TOKEN,
		PREVIEW_TOKEN: process.env.PREVIEW_TOKEN,
		STORYBLOK_SPACE_ID: process.env.STORYBLOK_SPACE_ID,
		REVALIDATE_SECRET: process.env.REVALIDATE_SECRET,
		NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
		NEXT_PUBLIC_PROJECT: process.env.NEXT_PUBLIC_PROJECT,
		CENTRA_INTEGRATION_TOKEN: process.env.CENTRA_INTEGRATION_TOKEN,
		LOGISTRA_API_KEY: process.env.LOGISTRA_API_KEY,
		LOGISTRA_SENDER_ID: process.env.LOGISTRA_SENDER_ID,
		NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
		NEXT_PUBLIC_MAPBOX_STYLE_URL: process.env.NEXT_PUBLIC_MAPBOX_STYLE_URL,
		NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,
		NEXT_PUBLIC_CENTRA_DOMAIN: process.env.NEXT_PUBLIC_CENTRA_DOMAIN,
		NEXT_PUBLIC_STASH_URL: process.env.NEXT_PUBLIC_STASH_URL,
		STASH_REVALIDATE_SECRET: process.env.STASH_REVALIDATE_SECRET,
		DEDUPE_SECRET: process.env.DEDUPE_SECRET,
		NEXT_PUBLIC_DEDUPE_URL: process.env.NEXT_PUBLIC_DEDUPE_URL,
	},
});
