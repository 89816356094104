"use client";

import { useDisplayType } from "@/features/products/product-list/components/Filters/hooks";
import type { FormattedMedia } from "@/lib/centra/formatters/media";
import { sizes } from "@/lib/utils";
import Image from "next/image";
import { Fragment, Suspense, useRef, type ReactNode } from "react";
import { useProductImage } from "./ProductDisplayProvider";
import styles from "./index.module.css";

const FALLBACK_IMAGE = "/no-product.jpg";

export const UnsuspendedProductCardImage = ({
	media,
	children,
}: {
	media: FormattedMedia[];
	children?: ReactNode;
}) => {
	const { display } = useDisplayType();
	const { selectedImage, setSelectedImage } = useProductImage();
	const ref = useRef<HTMLDivElement | null>(null);

	let image = null;

	if (!media || media.length === 0 || !media.at(0)) {
		image = {
			href: FALLBACK_IMAGE,
			alt: "No image found",
		};
	} else {
		image =
			display === "product"
				? media.find((img) => img.img_attributes_isPackshot === "1") || media.at(0)!
				: media.at(0)!;
	}

	const imageToDisplay = selectedImage ?? image;

	const handleMouseOver = (e: React.MouseEvent) => {
		const isVariant =
			(e.target as HTMLElement).classList.contains(styles.cardVariants!) ||
			(e.target as HTMLElement).classList.contains(styles.variantImg!);

		if (isVariant) return;

		const inverseImage =
			display === "product"
				? media.find((img) => img.img_attributes_isPackshot === "0")
				: media.find((img) => img.img_attributes_isPackshot === "1");

		if (inverseImage && display !== "product") {
			setSelectedImage(inverseImage);
		}
	};

	const handleMouseLeave = () => {
		setSelectedImage(null);
	};

	return (
		<div
			ref={ref}
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}
			style={{ width: "100%", aspectRatio: "4 / 5", position: "relative" }}>
			<Image
				fill
				src={imageToDisplay.href}
				style={{ objectFit: "cover" }}
				sizes={sizes(100, 50)}
				alt={image.alt}
			/>
			{children}
		</div>
	);
};

export const ProductCardImage: typeof UnsuspendedProductCardImage = (props) => {
	const media = props.media.at(0);

	return (
		<Fragment>
			<Suspense
				fallback={
					<div style={{ width: "100%", aspectRatio: "4 / 5", position: "relative" }}>
						<Image
							src={media?.href ?? FALLBACK_IMAGE}
							fill
							style={{ objectFit: "cover" }}
							sizes={sizes(100, 50)}
							alt={media?.alt ?? "No image found"}
						/>
					</div>
				}>
				<UnsuspendedProductCardImage {...props} />
			</Suspense>
		</Fragment>
	);
};
