"use client";

import { Close } from "@/components/icons";
import { Counter } from "@/components/ui/Counter";
import { IconButton } from "@/components/ui/iconButton";
import { centraClient } from "@/lib/centra/selection/fetchers";
import { centraAdapter } from "@/lib/gtm/data-layer";
import type { BaseSelection } from "@frend-digital/centra-types/selection";
import styles from "./index.module.css";

export const CartItemAmount = ({
	line,
	quantity,
}: {
	line: string;
	quantity: number;
}) => {
	const setQuantity = centraClient.useSetQuantity({
		optimistic: true,
		onSuccess: (selection, variables) => {
			if (variables.newQuantity < quantity) {
				// Remove
				centraAdapter.removeFromCart(selection as BaseSelection, variables.line);
			} else {
				// Add
				centraAdapter.addToCart(selection as BaseSelection, variables.line);
			}
		},
	});

	return (
		<Counter
			className={styles.counter}
			value={quantity}
			action={(value) => {
				setQuantity.mutate({
					line,
					newQuantity: value,
				});
			}}
		/>
	);
};

export const RemoveCartItem = ({ line }: { line: string }) => {
	const setQuantity = centraClient.useSetQuantity({
		optimistic: true,
		onSuccess: (selection, variables) => {
			centraAdapter.removeFromCart(selection as BaseSelection, variables.line);
		},
	});

	return (
		<IconButton
			className={styles.remove}
			onClick={() => {
				setQuantity.mutate({
					line,
					newQuantity: 0,
				});
			}}
			color="white"
			size={{
				base: "small",
				md: "medium",
			}}>
			<Close />
		</IconButton>
	);
};
