"use client";
/**
 * KEEP AT TOP LEVEL
 */
import "@/components/bloks/defineBloks";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { env } from "@/env.mjs";
import DeviceProvider from "@/features/Device/DeviceContext";
import { gtagUI } from "@/lib/gtm/components";
import { CentraServerProvider } from "@frend-digital/centra/client";
import { StoryblokProvider } from "@frend-digital/storyblok";
import { GoogleTagManager } from "@next/third-parties/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import { useCallback, useState, useTransition, type PropsWithChildren } from "react";
export default function Providers({
	children,
	preview,
	token,
}: PropsWithChildren<{ locale: string; preview: boolean; token: string }>) {
	const [, startTransition] = useTransition();
	const [client] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						refetchOnMount: false,
					},
				},
			}),
	);
	const router = useRouter();

	const handlePublish = useCallback(() => {
		startTransition(() => {
			router.refresh();
		});

		if (client.getQueriesData({}).length) {
			startTransition(async () => {
				await client.invalidateQueries();
			});
		}
	}, [router, client]);

	return (
		<DeviceProvider>
			<QueryClientProvider client={client}>
				<CentraServerProvider token={token}>
					<StoryblokProvider onPublished={handlePublish}>
						{children}
						{!preview && <GoogleTagManager gtmId={env.NEXT_PUBLIC_GTM_ID} />}
						<gtagUI.Pageview />
					</StoryblokProvider>
				</CentraServerProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</DeviceProvider>
	);
}
