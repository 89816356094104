"use client";

import {
	centraClientAPI,
	useSelection,
	type Selection,
} from "@/lib/centra/selection/fetchers";
import { formatSelection, useSelectionKey, useToken } from "@frend-digital/centra/client";
import { centraAddressSchema } from "@frend-digital/checkout/address";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import { useSelectedLayoutSegments } from "next/navigation";
import { useMemo } from "react";
import { z } from "zod";
import { fetchFreeShippingVouchers, fetchShippingOptions } from "./actions";

export type ShippingOption = {
	isPickupPoint: boolean | undefined;
	shippingMethod?: string;
	name?: string;
	price?: string;
	priceAsNumber?: number;
};

export const useSelectionAddress = () => {
	return useSelection({
		select: (data) => {
			const address = data.selection.address;

			return address;
		},
	});
};

export const useSelectionCountry = () => {
	return useSelection({
		select: (data) => {
			const country = data.location?.country;
			return country;
		},
	});
};

const numericRegex = /^\d+$/;

export const useAddressSchema = () => {
	const t = useTranslations("checkout.address");

	const { data: selectionAddressAndStates } = useSelectionAddressAndStates();
	const { data: selectionCountry } = useSelectionCountry();

	const schema = useMemo(() => {
		const primarySchema = z.object({
			address1: z.string().min(1, t("fieldError.addressRequired")),
			city: z
				.string()
				.min(1, t("fieldError.cityRequired"))
				.max(30, t("fieldError.cityLength")),
			country: z.string().min(1, t("fieldError.countryRequired")),
			email: z.string().email(t("fieldError.emailRequired")),
			firstName: z.string().min(1, t("fieldError.firstNameRequired")),
			lastName: z.string().min(1, t("fieldError.lastNameRequired")),
			phoneNumber: z.string().min(1, t("fieldError.phoneNumberRequired")),
			state: z.string().optional(),
			zipCode: z.string().min(1, t("fieldError.zipCodeRequired")),
		});

		if (selectionAddressAndStates?.requiresState) {
			const { states } = selectionAddressAndStates;
			const stateKeys = states.map((state) => state.state!);
			return primarySchema.omit({ state: true }).extend({
				state: z.enum(stateKeys as [string], { message: "A valid state is required" }),
			});
		}

		if (selectionCountry === "NO") {
			const invalidZipMessage = t("fieldError.invalidZip");
			return primarySchema.omit({ zipCode: true }).extend({
				zipCode: z
					.string()
					.min(4, invalidZipMessage)
					.max(4, invalidZipMessage)
					.regex(numericRegex, invalidZipMessage),
			});
		}

		return primarySchema;
	}, [t, selectionAddressAndStates, selectionCountry]);

	return schema;
};

export const useSelectionAddressAndStates = () => {
	return useSelection({
		select: (data) => {
			const address = data.selection.address;
			const country = data.selection.address?.country || data.location?.country;

			const foundCountry = data.countries?.find((c) => c.country === country);

			if (foundCountry && !!foundCountry.states?.length) {
				return {
					address,
					states: foundCountry.states,
					requiresState: true,
				};
			}

			return {
				address,
			};
		},
	});
};

export const useSelectionCountries = () => {
	return useSelection({
		select: (data) => ({
			countries: data.countries,
			userCountry: data.location?.country,
		}),
	});
};

export const useValidateAddress = () => {
	return useSelection({
		select: (data) => {
			const address = data.selection.address;
			const isValid = centraAddressSchema.safeParse(address).success;
			return isValid;
		},
	});
};

export const useSelectedShippingOption = () => {
	return useSelection({
		select: (data) => {
			return data.selection.shippingMethod;
		},
	});
};

export const useUpdatePickupPoint = () => {
	const selectionKey = useSelectionKey();
	const queryClient = useQueryClient();
	const token = useToken();

	return useMutation({
		mutationKey: ["addPickupPoint", token],
		mutationFn: async (id: string | null) => {
			const { data, error } = await centraClientAPI.PUT("/payment-fields", {
				headers: {
					"API-Token": token,
				},
				body: {
					additionalNotes: id
						? JSON.stringify({
								pickupPointId: id,
							})
						: "{}",
				},
			});

			if (error) throw error;

			return data;
		},
		onSuccess: (data) => {
			if (data) {
				const newSelection = formatSelection(data);
				queryClient.setQueryData<Selection>(selectionKey, () => {
					return newSelection;
				});
				queryClient.invalidateQueries({
					queryKey: selectionKey,
				});
			}
		},
	});
};

export const usePickupPoint = () => {
	return useSelection({
		select: (data) => {
			const notes = data.selection.additionalNotes;
			if (notes) {
				const parsedNotes = additionalNotes.safeParse(JSON.parse(notes));
				if (parsedNotes.success) {
					return parsedNotes.data.pickupPointId;
				}
			}

			return undefined;
		},
	});
};

export const useFreeShippingVouchers = () => {
	return useQuery({
		queryKey: ["freeShippingVouchers"],
		queryFn: () => fetchFreeShippingVouchers(),
	});
};

export const useShippingOptions = () => {
	const { data, isLoading } = useQuery({
		queryKey: ["shippingOptions"],
		queryFn: () => fetchShippingOptions(),
	});

	const { data: selectionShippingOptions, isLoading: selectionIsLoading } = useSelection({
		select: (data) => {
			return data.shippingMethods;
		},
	});

	const shippingOptions = useMemo(() => {
		if (!data || !selectionShippingOptions) return [];

		return selectionShippingOptions.reduce((acc, currentValue) => {
			const matchedData = data.find((item) => item.uri === currentValue.shippingMethod);

			return [
				...acc,
				{
					...currentValue,
					isPickupPoint: matchedData?.isPickupPoint,
				},
			];
		}, [] as ShippingOption[]);
	}, [data, selectionShippingOptions]);

	return {
		data: shippingOptions,
		isLoading: isLoading || selectionIsLoading,
	};
};

const additionalNotes = z.object({
	pickupPointId: z.string(),
});

export const useValidateShippingOption = () => {
	const shippingMethod = useSelectedShippingOption();
	const options = useShippingOptions();
	const pickupPoint = usePickupPoint();

	const isShippingValid = useMemo(() => {
		return !!options.data?.find((item) => {
			if (item.shippingMethod === shippingMethod.data) {
				if (item.isPickupPoint) {
					return !!pickupPoint.data;
				}
				return true;
			}
		});
	}, [shippingMethod.data, options.data, pickupPoint]);

	return {
		data: isShippingValid,
		isLoading: shippingMethod.isLoading || options.isLoading || pickupPoint.data,
	};
};

export const useCheckoutProgress = () => {
	const segment = useSelectedLayoutSegments();

	const { data: validAddress } = useValidateAddress();
	const { data: validShipping } = useValidateShippingOption();

	const isAddress = !segment.length;
	const isShipping = segment.includes("shipping");
	const isPayment = segment.includes("payment");

	const completedSteps = {
		address: !!validAddress,
		shipping: !!validShipping && !!validAddress,
		payment: false,
	};

	return {
		completedSteps,
		isAddress,
		isShipping,
		isPayment,
	};
};
