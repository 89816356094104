"use client";

import type { components } from "@frend-digital/centra-types/oas";
import type { BaseSelection } from "@frend-digital/centra-types/selection";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { useSelection } from "../centra/selection/fetchers";
import { centraAdapter, gtag } from "./data-layer";

const useRawSelection = () => {
	return useSelection({
		select: (data) => data.rawSelection,
	});
};

export function GTMViewCart() {
	const { data: selection } = useRawSelection();

	useEffect(() => {
		if (selection) {
			centraAdapter.viewCart(selection.selection as BaseSelection);
		}
	}, [selection]);

	return null;
}

export function GTMSearch({ query }: { query: string }) {
	useEffect(() => {
		gtag.search(query);
	}, [query]);
	return null;
}

export function GTMBeginCheckout() {
	const { data: selection } = useRawSelection();

	useEffect(() => {
		if (selection) {
			centraAdapter.beginCheckout(selection.selection as BaseSelection);
		}
	}, [selection]);

	return null;
}

export function GTMPurchase({
	order,
}: {
	order: components["schemas"]["OrderCompleteModel"];
}) {
	useEffect(() => {
		if (order) {
			centraAdapter.purchase(order);
		}
	}, [order]);

	return null;
}

export const PageView = () => {
	const pathname = usePathname();
	useEffect(() => {
		gtag.pageView();
	}, [pathname]);
	return null;
};

export const gtagUI = {
	ViewCart: GTMViewCart,
	Search: GTMSearch,
	BeginCheckout: GTMBeginCheckout,
	Purchase: GTMPurchase,
	Pageview: PageView,
};
